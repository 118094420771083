<!--
 * @Description: 负面词
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-18 20:25:41
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/content/sourceMaterial/mods/negativeWords.vue
-->
<template>
  <div>
    <div class="tip">
      <div class="tip-left">说明：</div>
      <div class="tip-right">
        <div>
          1、负面词是指会损害品牌形象、与品牌调性相悖,或与广告内容完全无关联的关键词。
        </div>
        <div>2、请在上传完所有负面词之后，点击提交审核。</div>
      </div>
    </div>
    <div
      class="source-material-list"
      v-if="tableData.length"
      v-loading="isLoading"
    >
      <div
        class="source-material-item"
        v-for="item in tableData"
        :key="item.id"
      >
        <div class="item-content">
          <div class="li"></div>
          <div class="li-text">{{ item.content }}</div>
        </div>
        <div class="item-remove">
          <span @click="handleRemove(item)">
            <i class="el-icon-delete"></i> 删除</span
          >
        </div>
      </div>
    </div>
    <el-empty v-else description="暂无上传"></el-empty>

    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";

const URL = {
  list: "api/customer/userMaterial/list",
  remove: "api/customer/userMaterial/remove",
};
export default {
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          materialType: 1,
          page,
          limit: size,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    handleRemove({ id }) {
      this.$confirm("确认删除此数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          HTTP({
            url: URL.remove,
            method: "post",
            data: {
              materialType: 1,
              materialIds: id,
            },
          }).then(() => {
            this.$message.success("删除成功");
            this.getList(1);
          });
        })
        .catch(() => {
          this.$message.info("已取消【删除】操作");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  display: flex;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
  font-weight: normal;
  color: #7c7f97;
  line-height: 24px;
  margin-top: 10px;
}
.source-material-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-left: 1px solid #e4e8fc;
  margin-top: 14px;
  .source-material-item {
    width: 25%;
    padding: 40px 20px;
    border-right: 1px solid #e4e8fc;
    border-bottom: 1px solid #e4e8fc;
    border-top: 1px solid #e4e8fc;
    .item-content {
      display: flex;
      align-items: center;
      justify-content: center;
      .li {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #7c7f97;
        margin-right: 10px;
      }
      .li-text {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #7c7f97;
      }
    }
    .item-remove {
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #7c7f97;
      margin-top: 20px;
      & span:hover {
        color: #4e6bef;
        cursor: pointer;
      }
    }
    &:hover {
      background-color: #f6f6fd;
    }
  }
}
</style>
